import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default ({threshold = 0, style, className, children}) => {
  const [visible, setVisible] = useState(false);
  const { ref, inView, entry } = useInView({
    threshold: threshold,
    rootMargin: '500px 0px 0px 0px',
  });

  useEffect(() => {
    if (inView && !visible) {
        setVisible(true);
    }
  }, [inView])

  return (
    <div 
      className={className}
      style={visible ? style : {backgroundColor: '#efefef'}}
      data-src={visible ? null : style}
      ref={ref}
    >
        {children}
    </div>
  );
}