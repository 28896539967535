import React from 'react'
import ImageInView from '../fx/animate/ImageInView';
// replace from context
import { baseUrl, forceHTTPS, forceCMS } from '../../util/auth/drupal';
import getImageSize, { getImageSizeFromJSON } from '../helper/imageStyleSizes';

const ImageFromField = ({img, imageSize = false, device, fallback, style, imgStyle, render = false, direct = false, format = "graphql"}) => {
    const fallbackStyle = {width: 'auto'} 

    const newStyle = {...style, ...imgStyle};

    if (imageSize) {
        let sizedImage = fallback;
        if (format === 'graphql') {
            sizedImage = getImageSize(img, imageSize);
            /* if (!sizedImage) {
                console.log('could not locate image', img);
            } */
        } else if(format === "json") {
            sizedImage = getImageSizeFromJSON(img, imageSize);
            /* if (!sizedImage) {
                console.log('could not locate image', img);
            } */
        }
        if (sizedImage) {
            return <ImageInView src={forceHTTPS(forceCMS(sizedImage))} render={render} style={style ? style : fallbackStyle} alt="" />
        }else if (fallback) {
            return <ImageInView src={fallback} render={render} style={style ? style : fallbackStyle} alt="" />
        }
    } 

    if (direct && img) {
        return <ImageInView src={forceHTTPS(forceCMS(img))} render={render} style={newStyle ? newStyle : fallbackStyle}  alt="" />
    }

    if (img != null && img.uri != null && img.uri.url != null) {
        let path = !img.uri.url.includes(baseUrl) ? baseUrl + img.uri.url : img.uri.url;
        return <ImageInView src={path} render={render} style={newStyle ? newStyle : fallbackStyle}  alt="" />
    }
    else if (fallback) {
        return <ImageInView src={fallback} render={render} style={style ? style : fallbackStyle} alt="" />
    }

    return null
}

export default ImageFromField