/* eslint-disable react/jsx-indent */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ScrollContainer } from '../../../context/scrollBehavior';
import AnimateOnEntrance from '../../fx/animate/AnimateOnEntrance';
import Link from '../../global/Link';
import { forceCMS, forceHTTPS } from '../../../util/auth/drupal';
import BGImageInView from '../../fx/animate/BGImageInView';

export default function HowCanWeHelp({ device }) {
  return (
    <StaticQuery
      query={graphql`
        query HowWeHelpQuery {
          blockContentBasic(
            drupal_id: { eq: "d0dfbe4e-468d-49a8-9f26-56ce29a429d7" }
          ) {
            body {
              processed
            }
            relationships {
              field_block_image {
                image_style_uri {
                  banner_manual_crop
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { body, relationships } = data.blockContentBasic;

        const image =
          relationships.field_block_image[
            Math.floor(Math.random() * relationships.field_block_image.length)
          ].image_style_uri.find((i) => i.banner_manual_crop); // find the first reference of banner_manual_crop that is not null in the array

        return (
          <BGImageInView
            className="footer-cta"
            style={{
              backgroundImage: `url(${forceHTTPS(
                forceCMS(image.banner_manual_crop)
              )})`,
            }}
            >
                <ScrollContainer force={true}>
                  <AnimateOnEntrance className="fadeInUp" device={device}>
                    <section className="grid-section grid-content-container t-center">
                      <div className="spacer"></div>
                      <div className="t-content-container t-center">
                        <span
                          className="t-content"
                          dangerouslySetInnerHTML={{ __html: body.processed }}
                        ></span>
                      </div>
                      <div className="spacer"></div>
                      <Link
                        path={'/services'}
                        button
                        className={`btn-center`}
                        device={device}
                      >
                        See all services
                      </Link>
                      <div className="spacer"></div>
                    </section>
                  </AnimateOnEntrance>
                </ScrollContainer>
          </BGImageInView>
        );
      }}
    />
  );
}
