export const useQuery = (basepath, pathname) => {
    const pathparts = pathname.replace(`/${basepath}/`, '').split('/')
    let pathobject = {}
    let pathKey = ''
    pathparts.map((part, i) => {
        const isOdd = (i+1) % 2
        if (isOdd) {
            pathKey = part
        }
        if (!isOdd) {
            pathobject[pathKey] = part
        }
    })
    return pathobject
};

export const decodeHTML = function (str) {
    if (str) {
        return str.replaceAll("%20", " ")
    }
};