import React from 'react';
import { Link } from 'gatsby';
import ImageFromField from './ImageFromField';

const ArtistCard = ({ artist, className = '', device, json = false }) => (
  <div className={className}>
    <Link to={artist.path.alias} className="card artist-card">
      <div className="card-image">
        <figure className="image is-3by3">
          <ImageFromField
            img={artist.relationships.field_artist_listing_image}
            device={device}
            format={json ? 'json' : 'graphql'}
            fallback={'./media/logo.png'}
            imageSize="gatsby_artist_pic"
          />
        </figure>
      </div>
      <div className="card-content">
        <h3 className="title is-4 t-center">
          {artist.field_lastname}, {artist.field_firstname}
        </h3>
      </div>
    </Link>
  </div>
);

export default ArtistCard;
