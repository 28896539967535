const PreloadImageList = async (images) => {
    const preloadImageLimit = 25; // cap for now
    //const preloadImageLimit = images.length; // later we can increment the list from pagination
    for(var i=0; i < preloadImageLimit; i++) {
        let image = images[i];
        //console.log('loading', image);
        await loadImage(image);
    }
}
export default PreloadImageList 

const loadImage = async (image) => {
    return new Promise(resolve => {
        let newImage = new Image();
        newImage.onload = function () {
            resolve(true);
            //console.log('> preloaded: ', image);
        }
        newImage.onerror = function (e) {
            resolve(false);
            //console.log('>> ! error loading: ', image, e);
        }
        newImage.src = image;
    });
}