import React, { useState } from 'react';
import Ripple from '../fx/ripple/Ripple';
import Register from './Register';
import Modal from './Modal';
import Login from './Login';
import BecomeAMember from './BecomeAMember';
import BecomeAMemberFlow from './BecomeAMemberFlow';

const RegisterSignUp = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  return (
    <>
      <Ripple className="form">
        <fieldset>
        <BecomeAMemberFlow className="button">
        BECOME A MEMBER
        </BecomeAMemberFlow>
        {/* <button class="button" type="submit" onClick={() => {
          setIsOpen(true);
        }}>
          BECOME A MEMBER
        </button> */}
        </fieldset>
      </Ripple>
      <Modal active={isOpen} onClose={() => setIsOpen(false)}>
        <BecomeAMember
          onLogin={() => {
            setIsLoginOpen(true);
            setIsOpen(false);
          }}
        />
      </Modal>
      <Modal active={isLoginOpen} onClose={() => setIsLoginOpen(false)}>
        <Login 
          setLoginModalActive={setIsLoginOpen}
        />
      </Modal>
    </>
  );
};

export default RegisterSignUp;
