import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Link, navigate, useStaticQuery } from 'gatsby'
import SearchIcon from '../assets/media/search-dark.svg'
import ImageFromField from './global/ImageFromField'
import { useQuery, decodeHTML } from './helper/pagePaths'
import { useLocation } from "@reach/router"
import ArtistCard from './global/ArtistCard'
import PreloadImageList from './helper/preloadImageList'
import GetImageSize from './helper/imageStyleSizes';
import { baseUrl } from '../util/auth/cms';
import { getImageSizeWithCMS } from './helper/imageStyleSizes';
import FilterIcon from '../assets/media/filter.svg'

const Artists = ({device, basePath, location}) => {

    const data = useStaticQuery(graphql`
            query {
                artists: allNodeArtist( filter: {status: {eq: true}}, sort: {fields: field_lastname}) {
                    edges {
                    node {
                        path {
                            alias
                        }
                        drupal_id
                        field_firstname
                        field_lastname
                        field_featured_contemporary
                        field_featured_historic
                        relationships {
                            field_artist_type {
                                name
                                id
                            }
                            field_artist_origin {
                                name
                            }
                            field_artist_listing_image {
                                uri {
                                    url
                                }
                                image_style_uri {
                                    gatsby_artist_pic
                                }
                            }
                        }
                    }
                    }
                }
            }
    `)

    const pageLength = 8
    useEffect(() => {
        const artists = data?.artists?.edges;
        setArtists(artists);
    }, [data])

    const [ preloaded, setPreloaded ] = useState("initialize");
    useEffect(() => {
        const artists = data?.artists?.edges;

        const preloadImages = (e) => {
            e.stopPropagation();
            if (preloaded === "initialize") {
                setPreloaded(false);
            }
        }

        if (data && preloaded === false) {
            setPreloaded(3);
        }

        // set up image event handlers
        window.addEventListener('mousemove', preloadImages);
        window.addEventListener('touchmove', preloadImages);

        return () => {
            window.removeEventListener('mousemove', preloadImages);
            window.removeEventListener('touchmove', preloadImages);
        };

    }, [data, preloaded])

    useEffect(() => {
        if (preloaded > 0) {
          // preload page - 1 to pagelength * preloaded index
          // images that are re-run from current page should be served from cache
          const preloadArtwork = artists.slice(page - 1, pageLength*preloaded);
          // set preloaded to page 2
          PreloadImageList(
            preloadArtwork.map((artists) => {
              return getImageSizeWithCMS(artists.node.relationships?.field_artist_listing_image, 'gatsby_artist_pic')
            })
          )
        }
    }, [preloaded, setPreloaded])

    const [ letter, setLetter ] = useState(0)
    const [ type, setType ] = useState(0)
    const [ origin, setOrigin ] = useState(0)
    const [ search, setSearch ] = useState(0)
    const [ page, setPage ] = useState(1)
    const [ matchingArtists, setMatchingArtists ] = useState([]);
    const [ artists, setArtists] = useState([]);
    const [toggleFilters, setToggleFilters] = useState(false);

    // load query params on init
    const pathname = useLocation().pathname
    const params = useQuery(basePath, pathname)

    useEffect(() => {
        const {
            letter: uletter,
            type: utype,
            origin: uorigin,
            search: usearch
        } = params
        if (uletter !== letter) {
            setLetter(uletter)
        }
        if (utype !== type) {
            setType(decodeHTML(utype))
        }
        if (uorigin !== origin) {
            setOrigin(uorigin)
        }
        if (usearch !== search) {
            setSearch(decodeHTML(usearch))
        }
    }, [pathname])

    // update path
    // with page, leaving off for now
    useEffect(() => {
        navigate(updatePath({letter, type, origin, search}))
    }, [letter, setLetter, type, setType, origin, setOrigin, search, setSearch])

    // update the path dynamically
    const updatePath = (pathParams) => {
        let pathParts = `/${basePath}`
        if (letter) {
            pathParts += `/letter/${letter}`
        }
        if (origin) {
            pathParts += `/origin/${origin}`
        }
        if (type) {
            pathParts += `/type/${type}`
        }
        if (search) {
            pathParts += `/search/${search}`
        }
        /* if (page > 1) {
            pathParts += `/page/${page}`
        } */
        return pathParts
    }

    // define infinite scrolling behavior
    const infiniteScroller = useRef(null);

    const handleScroll = () => {
        if (isInViewport(infiniteScroller)) {
            setPage(page+1)
            setPreloaded(preloaded + 1);
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll, page, setPage])

    const isInViewport = (el, offset = 0) => {
        if (!el.current) return false;
        const top = el.current.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    const changeLetter = (to) => {
        if (letter === to) {
            setLetter(0)
        } else {
            setLetter(to)
        }
        setPage(1)
    }

    const changeType = (new_type) => {
        if (type === new_type) {
            setType(0)
        } else {
            setType(new_type)
        }
        setPage(1)
    }

    const changeOrigin = (new_origin) => {
        if (origin === new_origin) {
            setOrigin(0)
        } else {
            setOrigin(new_origin)
        }
        setPage(1)
    }

    const changeSearch = (e) => {
        const keyword = e.target.value
        setSearch(keyword)
        setPage(1)
    }

    const alphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    useEffect(() => {
        filterSearchResults()
    }, [search, origin, letter, type, page])

    const filterSearchResults = () => {
        let allArtists = artists;

        // filter by first letter of last name
        if (letter) {
            const regex = new RegExp(`^${letter}`, 'i')
            allArtists = allArtists.filter(({node: artist}) => artist.field_lastname.match(regex) )
        }

        // filter by origin
        if (origin) {
            allArtists = allArtists.filter(({node: artist}) => artist.relationships.field_artist_origin.name === origin)
        }

        // filter by artist type
        // historic, contemporary
        if (type) {
            allArtists = allArtists.filter(({node: artist}) => artist.relationships.field_artist_type.name === type )
        }

        // filter by search keyword
        if (search) {
            const searchWords = search.split(" ");
            const regex = /[^\w\s]/gi
            allArtists = allArtists.filter(({node: artist}) => {
                let matchWords = 0;
                searchWords.forEach((searchWord) => {
                    const sanitizeSearch = searchWord.replace(regex, "")
                    const searchRegExp = new RegExp(`^.*${sanitizeSearch}.*$`, "i")
                    const safeFirstName = artist.field_firstname.replace(regex, "")
                    const safeLastName = artist.field_lastname.replace(regex, "")
                    if (safeFirstName.match(searchRegExp) || safeLastName.match(searchRegExp)) {
                        matchWords++;
                    }
                })
                if (matchWords === searchWords.length) {
                    return true;
                }
                return false;
            })
        }

        // get the page results
        if (allArtists.length > (pageLength * page)) {
            // paginate
            allArtists = allArtists.slice(0, (pageLength * page))
        }

        setMatchingArtists(allArtists);
    }

    if (!artists.length) {
        return <p>Loading</p>
    }

    return (
        <div className="artists" data-params={params}>
            <section className="section artists-page-title">
                <div className="container">
                    <div className="columns">
                        <div className="column page-title">
                            <h2>Search Artist</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section filters">
                <div className="container">
                    <div className="columns">
                        <div className="s-c"></div>
                        <div className="artist-type mobile-no"><h3>Artist Type</h3></div>
                    </div>
                    <div className="columns">
                        <div className="search-container">
                            <div className="field search-field">
                                <div className="control has-icons-left">
                                    <input className="input" placeholder="Search for an artist’s name" type="text" value={search ? search : ''} onChange={changeSearch} />
                                    <span className="icon is-small is-left">
                                        <img src={SearchIcon} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="artwork_filters_toggle_mobile">
                            <span className="ripple-wrapper">
                            <button
                                className={`button btn-outline artworkfilters ${toggleFilters ? 'active' : ''}`}
                                onClick={() =>  setToggleFilters(!toggleFilters)}
                            >
                                Filter Artwork <img src={FilterIcon} className="art-filters" />
                            </button>
                            </span>
                        </div>
                        <div id="artwork_filters" className={`columns is-multiline ${toggleFilters ? 'active' : ''}`}>
                        <div className="column artist-type mobile-yes"><h3>Artist Type</h3></div>
                            <div className="column flex-grow-0">
                                <label className="checkbox">
                                    Contemporary
                                    <input type="checkbox" checked={type === 'Established living contemporary'} />
                                    <span className="checkmark" onClick={() => changeType('Established living contemporary')}></span>
                                </label>
                            </div>
                            <div className="column flex-grow-0">
                                <label className="checkbox">Historical
                                    <input type="checkbox" checked={type === 'Historical works of significance'} />
                                    <span className="checkmark" onClick={() => changeType('Historical works of significance')}></span>
                                </label>
                            </div>
                            <div className="column flex-grow-0">
                                <label className="checkbox">International
                                    <input type="checkbox" checked={origin === 'International'} />
                                    <span className="checkmark" onClick={() => changeOrigin('International')}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="artwork_filters" className={`section letters ${toggleFilters ? 'active' : ''}`}>
                <div className="column is-three-fifths filter-by-name mobile-yes spacer-top"><h3>Filter by Name</h3></div>
                <nav className="pagination is-centered artist-nav" role="navigation" aria-label="pagination">
                    <ul className="pagination-list is-gapless">
                        {alphabet.map((alpha) => {
                            let link = null
                            if (letter === alpha && letter) {
                                link = <a class={`pagination-link is-current`} aria-label={`Page ${alpha}`}  onClick={() => changeLetter(alpha)} aria-current="page">{alpha}</a>
                            } else {
                                link = <a class={`pagination-link`} aria-label={`Goto Page ${alpha}`} onClick={() => changeLetter(alpha)}>{alpha}</a>
                            }
                            return (
                                <li key={`letter_${alpha}`}>
                                    {link}
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </section>
            <section className="section artists-cards artists-search-cards">
                <div className="columns">
                    {matchingArtists.length ?
                        matchingArtists.map(({node: artist}, i) => {

                        return (
                            <ArtistCard artist={artist} className="column is-3"  key={artist.drupal_id} device={device} />
                        )
                    }) :
                    (
                        <div className="container">
                            <h3>Sorry, we didn't find any results for that filter</h3>
                        </div>
                    )
                    }
                </div>
            </section>
            <div className="infinite-scroll-detection" ref={infiniteScroller} style={{padding: '40px'}}>&nbsp;</div>
        </div>
)}

export default Artists