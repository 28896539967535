/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import AnimateOnEntrance from '../components/fx/animate/AnimateOnEntrance'
import { ScrollContainer } from '../context/scrollBehavior'

import RegisterSignUp from '../components/global/RegisterSignUp'
import Artists from '../components/Artists'
import SEO from '../components/SEO'
import HowCanWeHelp from '../components/layout/landingPage/HowCanWeHelp'
import ImageInView from '../components/fx/animate/ImageInView';

// determine image sourcing by env
import useAuth from '../util/hooks/useAuth'

const ArtistsPage = ({data, location}) => {
    useAuth();
    const [device, setDevice ] = useState('mobile')

    return (
        <>
        <SEO title="Artists" />
        <Layout device={device} setDevice={setDevice} logo={data.logo} menu={data.allMenuMenuLink} footerMenu={data.footer_menu} locations={data.locations.edges} className={'artists-main-page'}>
            <ScrollContainer force={true}>
                <Artists artists={data.artists} device={device} basePath={`artists`} location={location} />
            </ScrollContainer>
            <ScrollContainer force={true}>
                <section className="grid-section grid-content-container t-left set-up-account">
                    <div className="t-content-container t-left">
                        <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                            <div className="grid-col form-set-up-account">
                                <div className="t-heading-container">
                                    <AnimateOnEntrance className="fadeInLeft" device={device}>
                                        <h3 className="t-mxlarge t-heading">Become a member!</h3>
                                    </AnimateOnEntrance>
                                    <div className="t-content-container t-left t-callout">
                                        <AnimateOnEntrance className="fadeInLeft" device={device}>
                                            <p>
                                            As a member of My Loch Gallery you can set up a collector profile, save artworks and be notified of new work by your favourite atists, access to our newsletter, and more.
                                            </p>
                                        </AnimateOnEntrance>
                                    </div>
                                </div>
                                <AnimateOnEntrance className="fadeInLeft" device={device}>
                                    <RegisterSignUp />
                                </AnimateOnEntrance>
                            </div>
                            <div className="grid-col mobile-no">
                                <AnimateOnEntrance className="fadeInRight" device={device}>
                                    <ImageInView src={"/media/su_grid.jpg"} />
                                </AnimateOnEntrance>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollContainer>
            <HowCanWeHelp/>
        </Layout>
        </>
    )
}

export default ArtistsPage

/* export const ArtistsQuery = graphql`
query ArtistsQuery {
    artists: allNodeArtist(sort: {fields: field_lastname}) {
        edges {
          node {
            path {
                alias
            }
            field_firstname
            field_lastname
            field_featured_contemporary
            field_featured_historic
            relationships {
                field_artist_type {
                    name
                    id
                }
                field_artist_origin {
                    name
                }
                field_artist_listing_image {
                    uri {
                        url
                      }
                }
            }
          }
        }
    }
    allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "gatsby-menu"}}) {
        edges {
            node {
            enabled
            title
            expanded
            external
            langcode
            weight
            link {
                uri
            }
            drupal_parent_menu_item
            bundle
            drupal_id
            menu_name
            }
        }
    }   
    locations: allNodeLocation {
        edges {
          node {
            field_address {
              address_line1
              address_line2
              country_code
              locality
              postal_code
            }
            field_address_description
            field_address_short
            field_location_email
            field_location_fax_number
            field_location_phone_number
            title
            field_office_hours_description
          }
        }
    }
}
` */
// #ORIGIN: removed for testing

export const ArtistsQuery = graphql`
query ArtistsQuery {
    artists: allNodeArtist( filter: {status: {eq: true}}, sort: {fields: field_lastname}) {
        edges {
          node {
            path {
                alias
            }
            drupal_id
            field_firstname
            field_lastname
            field_featured_contemporary
            field_featured_historic
            relationships {
                field_artist_type {
                    name
                    id
                }
                field_artist_origin {
                    name
                }
                field_artist_listing_image {
                    uri {
                        url
                    }
                }
            }
          }
        }
    }
    allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "gatsby-menu"}}) {
        edges {
            node {
            enabled
            title
            expanded
            external
            langcode
            weight
            link {
                uri
            }
            drupal_parent_menu_item
            bundle
            drupal_id
            menu_name
            }
        }
    }   
    locations: allNodeLocation {
        edges {
          node {
            field_address {
              address_line1
              address_line2
              country_code
              locality
              postal_code
            }
            field_address_description
            field_address_short
            field_location_email
            field_location_fax_number
            field_location_phone_number
            title
            field_office_hours_description
          }
        }
    }
    footer_menu: allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "footer"}}) {
        edges {
            node {
                enabled
                title
                expanded
                external
                langcode
                weight
                link {
                    uri
                }
                bundle
                drupal_id
                menu_name
            }
        }
    }
}
`