import { baseUrl, forceHTTPS, forceCMS } from '../../util/auth/drupal';

const getImageSize = (imageStyles, imageSizeName) => {

    let image = false;

    if (imageStyles?.image_style_uri && imageStyles?.image_style_uri.length && imageSizeName) {
        for (var i = 0; i < imageStyles.image_style_uri.length; i++) {
            let size = imageStyles.image_style_uri[i];
            if (size[imageSizeName]) {
                image = size[imageSizeName];
            }
        }
    }

    // if still false, try JSON method
    if (!image) {
        image = getImageSizeFromJSON(imageStyles, imageSizeName);
    }

    return image;
}
export default getImageSize

export const getImageSizeFromJSON = (imageStyles, imageSizeName) => {
    let image = false;

    if (imageStyles?.uri?.image_style_uri && imageStyles?.uri?.image_style_uri.length && imageSizeName) {
        for (var i = 0; i < imageStyles.uri.image_style_uri.length; i++) {
            let size = imageStyles.uri.image_style_uri[i];
            if (size[imageSizeName]) {
                image = size[imageSizeName];
            }
        }
    }

    return image;
}

export const getImageSizeWithCMS = (imageStyles, imageSizeName) => {
    const imagePath = getImageSize(imageStyles, imageSizeName);
    return imagePath ? forceHTTPS(forceCMS(imagePath)) : false;
}